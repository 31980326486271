import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import firebase from 'firebase';

export const fetchConfig = createAsyncThunk('config/fetchConfig', async () => {
  const docRef = firebase.firestore().doc('configs/dataConfig');
  const doc = await docRef.get();
  //await convertChartsDb(doc.data());
  return doc.data();
});

export const configSlice = createSlice({
  name: 'config',
  initialState: {
    configStatus: 'idle',
    configError: null,
    authorisationStatus: null,
    sidebarShow: 'responsive',
    stream: '',
    platform: '',
    capturePoint: '',
    buildType: '',
    defaultStream: '',
    defaultPlatform: '',
    defaultBuildType: '',
    defaultCapturePoint: '',
    availableStreams: [],
    availablePlatforms: [],
    availableBuildTypes: [],
    availableCapturePoints: [],
  },
  reducers: {
    set: (state, action) => {
      Object.assign(state, action.payload);

      // Update user filter
      const props = action.payload;
      if (props.stream || props.platform || props.buildType || props.capturePoint) {
        localStorage.setItem(
          'filter',
          JSON.stringify({
            stream: state.stream,
            platform: state.platform,
            buildType: state.buildType,
            capturePoint: state.capturePoint,
          })
        );
      }
    },
  },
  extraReducers: {
    [fetchConfig.pending]: (state, action) => {
      state.configStatus = 'loading';
    },
    [fetchConfig.fulfilled]: (state, action) => {
      state.configStatus = 'idle';
      state.configError = null;
      state.authorisationStatus = 'authorised'; // If we can fetch the config, we are authorised.

      const cfg = action.payload;
      Object.assign(state, cfg);
      state.stream = cfg.defaultStream;
      state.platform = cfg.defaultPlatform;
      state.buildType = cfg.defaultBuildType;
      state.capturePoint = cfg.defaultCapturePoint;

      // Load user filter
      const userFilter = JSON.parse(localStorage.getItem('filter'));
      if (userFilter) {
        if (userFilter.stream && state.availableStreams.indexOf(userFilter.stream) >= 0) {
          state.stream = userFilter.stream;
        }
        if (userFilter.platform && state.availablePlatforms.indexOf(userFilter.platform) >= 0) {
          state.platform = userFilter.platform;
        }
        if (userFilter.buildType && state.availableBuildTypes.indexOf(userFilter.buildType) >= 0) {
          state.buildType = userFilter.buildType;
        }
        if (userFilter.capturePoint && state.availableCapturePoints.indexOf(userFilter.capturePoint) >= 0) {
          state.capturePoint = userFilter.capturePoint;
        }
      }
    },
    [fetchConfig.rejected]: (state, action) => {
      state.configStatus = 'error';
      state.configError = action.error.message ?? 'Unknown error';
      console.log(action.error);
      state.authorisationStatus = 'unauthorised'; // If we can't read the config, we are not authorised.
    },
  },
});

export const { set } = configSlice.actions;

export default configSlice.reducer;
