import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import firebase from 'firebase';

export const fetchStats = createAsyncThunk('stats/fetchStats', async args => {
    const query = firebase.firestore()
        .collection('captures')
        .where('stream', '==', args.stream)
        .where('platform', '==', args.platform)
        .where('buildType', '==', args.buildType)
        .where('capturePoint', '==', args.capturePoint)
        .where('changelist', '>', 0)
        .orderBy('changelist', 'asc')
        .limitToLast(100);

    const snapshot = await query.get();
    const docs = snapshot.docs.map(d => d.data());
    return docs;
});

export const statsSlice = createSlice({
    name: 'stats',
    initialState: {
        statsStatus: 'idle',
        statsError: null,
        captures: [],
    },
    reducers: {},
    extraReducers: {
        [fetchStats.pending]: (state, action) => {
            state.statsStatus = 'loading';
        },
        [fetchStats.fulfilled]: (state, action) => {
            state.statsStatus = 'idle';
            state.statsError = null;
            state.captures = action.payload;
        },
        [fetchStats.rejected]: (state, action) => {
            state.statsStatus = 'error';
            state.statsError = action.error.message ?? 'Unknown error';
            console.log(action.error);
        }
    },
})

export default statsSlice.reducer;
