import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import 'core-js';
import './polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import firebase from 'firebase/app';

import { icons } from './assets/icons';

import { Provider } from 'react-redux';
import store from './store';

import { refresh } from './slices/authSlice';

import { fetchConfig } from './slices/configSlice';

import { fetchCharts } from './slices/chartsSlice';

// Initialise Firebase
const firebaseConfig = {
  apiKey: 'AIzaSyDgc4nt9LG3dU6Rm2k68Olf2IKgiBiO_f8',
  authDomain: 'perfstats-c65cf.firebaseapp.com',
  projectId: 'perfstats-c65cf',
  storageBucket: 'perfstats-c65cf.appspot.com',
  messagingSenderId: '536225812726',
  appId: '1:536225812726:web:ba1fc73addfc93f3ef1cd7',
  measurementId: 'G-H7HSFMDT6Q',
};

firebase.initializeApp(firebaseConfig);
firebase.auth().onAuthStateChanged((user) => {
  store.dispatch(refresh()); // Refresh auth related state
  if (user) {
    // We are authenticated now, try to fetch the config
    store.dispatch(fetchConfig());
    store.dispatch(fetchCharts());
  }
});

React.icons = icons;

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
