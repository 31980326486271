export const logoIcon = ['160 160', `
  <title>TK logo</title>
  <g
      id="g3838"
      transform="matrix(1.2631844,0,0,1.2631844,-118.60366,-45.674622)"
      style="fill:#ffffff">
    <g
        style="fill:#ffffff"
        transform="matrix(1.4370305,0,0,-1.4370305,157.10456,137.95382)"
        id="g438">
      <path
          inkscape:connector-curvature="0"
          id="path440"
          style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
          d="m 0,0 c -17.042,0 -30.907,13.865 -30.907,30.907 0,17.042 13.865,30.907 30.907,30.907 17.043,0 30.908,-13.864 30.908,-30.907 C 30.908,13.865 17.043,0 0,0 M 24.027,54.935 C 17.609,61.353 9.076,64.887 0,64.887 c -18.736,0 -33.979,-15.243 -33.979,-33.98 0,-18.736 15.243,-33.979 33.979,-33.979 18.736,0 33.98,15.243 33.98,33.979 0,9.077 -3.535,17.61 -9.953,24.028" />
    </g>
    <g
        style="fill:#ffffff"
        transform="matrix(1.4370305,0,0,-1.4370305,181.00639,77.777446)"
        id="g442">
      <path
          inkscape:connector-curvature="0"
          id="path444"
          style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
          d="m 0,0 -11.764,-10.591 c -0.123,-0.111 -0.193,-0.269 -0.193,-0.435 v -4.532 c 0,-0.166 -0.07,-0.323 -0.193,-0.435 l -1.438,-1.295 c -0.048,-0.042 -0.122,-0.008 -0.122,0.055 V 5.98 c 0,2.041 -1.309,2.923 -2.923,2.923 -1.613,0 -2.922,-0.882 -2.922,-2.923 v -23.213 c 0,-0.063 -0.075,-0.097 -0.122,-0.055 l -1.438,1.295 c -0.123,0.112 -0.194,0.269 -0.194,0.435 v 4.532 c 0,0.166 -0.07,0.324 -0.193,0.435 L -33.265,0 c -1.516,1.366 -3.047,0.983 -4.127,-0.216 -1.08,-1.2 -1.3,-2.762 0.217,-4.127 l 10.784,-9.711 c 0.129,-0.116 0.129,-0.318 0,-0.434 l -8.154,-7.343 c -1.518,-1.365 -1.297,-2.927 -0.217,-4.127 1.08,-1.199 2.611,-1.582 4.127,-0.216 l 8.755,7.883 c 0.056,0.05 0.14,0.05 0.195,0 l 2.034,-1.831 c 0.061,-0.055 0.096,-0.134 0.096,-0.216 v -7.579 c 0,-2.041 1.309,-2.922 2.922,-2.922 1.614,0 2.923,0.881 2.923,2.922 v 7.579 c 0,0.082 0.035,0.161 0.097,0.216 l 2.032,1.83 c 0.056,0.051 0.141,0.051 0.196,0 l 8.754,-7.882 c 1.518,-1.366 3.048,-0.983 4.127,0.216 1.08,1.2 1.301,2.762 -0.217,4.127 l -8.154,7.343 c -0.129,0.116 -0.129,0.318 0,0.434 L 3.91,-4.343 c 1.517,1.365 1.296,2.927 0.217,4.127 C 3.046,0.983 1.517,1.366 0,0" />
    </g>
  </g>
`]
