import { configureStore } from '@reduxjs/toolkit'
import configReducer from './slices/configSlice'
import authReducer from './slices/authSlice'
import statsReducer from './slices/statsSlice';
import chartsReducer from './slices/chartsSlice';

export default configureStore({
  reducer: {
    config: configReducer,
    auth: authReducer,
    stats: statsReducer,
    charts: chartsReducer,
  },
});
