import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import firebase from 'firebase';

export const fetchCharts = createAsyncThunk('charts/fetchCharts', async () => {
  const collectionRef = firebase.firestore().collection('charts');
  const chartsSnapshot = await collectionRef.get();
  const charts = chartsSnapshot.docs.reduce((acc, c) => {
    acc[c.id] = c.data();
    return acc;
  }, {});
  return charts;
});

export const chartsSlice = createSlice({
  name: 'charts',
  initialState: {
    chartsStatus: 'idle',
    chartsError: null,
    charts: {}
  },
  reducers: {
    set: (state, action) => {
      Object.assign(state.charts, action.payload);
    },
  },
  extraReducers: {
    [fetchCharts.pending]: (state, action) => {
      state.chartsStatus = 'loading';
    },
    [fetchCharts.fulfilled]: (state, action) => {
      state.chartsStatus = 'idle';
      state.chartsError = null;

      const charts = action.payload;
      Object.assign(state.charts, charts);
    },
    [fetchCharts.rejected]: (state, action) => {
      state.chartsStatus = 'error';
      state.chartsError = action.error.message ?? 'Unknown error';
      console.log(action.error);
    },
  },
});

export const { set } = chartsSlice.actions;

export default chartsSlice.reducer;
