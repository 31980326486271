import { createSlice } from '@reduxjs/toolkit'
import firebase from 'firebase';

const DEFAULT_PIC_URL = '/avatars/placeholder.png';

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        isSignedIn: false,
        displayName: '',
        profilePicUrl: DEFAULT_PIC_URL,
    },
    reducers: {
        refresh: (state, action) => {
            const auth = firebase.auth();
            if (auth.currentUser) {
                state.isSignedIn = true;
                state.displayName = auth.currentUser.displayName;
                state.profilePicUrl = auth.currentUser.photoURL;
            } else {
                state.isSignedIn = false;
                state.displayName = '';
                state.profilePicUrl = DEFAULT_PIC_URL;
            }
        },
    }
})

export const {
    refresh,
} = authSlice.actions;

export default authSlice.reducer;