export const logo = ['577.49627 97.659155',`
<g
transform="translate(-108.2757,-44.709222)"
id="layer1"
inkscape:groupmode="layer"
inkscape:label="Layer 1">
<g
  style="fill:#27a8d8;fill-opacity:1"
  id="g398"
  transform="matrix(1.4370305,0,0,-1.4370305,627.09359,60.892334)">
 <path
    d="m 0,0 h 14.253 c 4.103,0 5.819,-0.941 5.819,-6.77 v -7.751 c 0,-5.754 -1.716,-6.684 -5.819,-6.684 H 0 Z m -2.726,5.279 c -1.505,0 -2.727,-0.815 -2.727,-2.702 v -26.308 c 0,-1.878 1.208,-2.7 2.704,-2.707 h 17.392 c 7.566,0 10.794,3.551 10.794,11.874 v 7.794 c 0,8.446 -3.228,12.049 -10.794,12.049"
    style="fill:#27a8d8;fill-opacity:1;fill-rule:nonzero;stroke:none"
    id="path400"
    inkscape:connector-curvature="0" />
</g>
<g
  style="fill:#27a8d8;fill-opacity:1"
  id="g402"
  transform="matrix(1.4370305,0,0,-1.4370305,607.16872,53.305817)">
 <path
    d="m 0,0 c -1.506,0 -2.725,-0.815 -2.725,-2.702 v -19.43 l -19.441,21.276 c -0.5,0.585 -1.271,0.856 -2.135,0.856 -1.506,0 -2.724,-0.815 -2.724,-2.702 v -26.314 c 0,-1.887 1.218,-2.702 2.724,-2.702 1.506,0 2.729,0.815 2.729,2.702 V -9.589 L -2.127,-30.87 c 0.5,-0.58 1.267,-0.848 2.127,-0.848 1.505,0 2.728,0.815 2.728,2.702 V -2.702 C 2.728,-0.815 1.505,0 0,0"
    style="fill:#27a8d8;fill-opacity:1;fill-rule:nonzero;stroke:none"
    id="path404"
    inkscape:connector-curvature="0" />
</g>
<g
  id="g406"
  transform="matrix(1.4370305,0,0,-1.4370305,558.06986,53.95708)"
  style="fill:#00222b">
 <path
    d="M 0,0 C -1.304,0.752 -2.769,0.658 -3.712,-0.976 L -18.78,-27.109 c -0.944,-1.634 -0.293,-2.95 1.01,-3.703 1.305,-0.753 2.767,-0.657 3.71,0.977 L 1.009,-3.702 C 1.952,-2.067 1.305,-0.752 0,0"
    style="fill:#00222b;fill-opacity:1;fill-rule:nonzero;stroke:none"
    id="path408"
    inkscape:connector-curvature="0" />
</g>
<g
  style="fill:#27a8d8;fill-opacity:1"
  id="g410"
  transform="matrix(1.4370305,0,0,-1.4370305,520.96239,54.61596)">
 <path
    d="m 0,0 c -1.065,1.065 -2.503,1.351 -3.838,0.016 l -12.41,-12.41 H -22.4 V -1.79 c 0,1.887 -1.223,2.702 -2.729,2.702 -1.506,0 -2.725,-0.815 -2.725,-2.702 v -26.314 c 0,-1.888 1.219,-2.702 2.725,-2.702 1.506,0 2.729,0.814 2.729,2.702 v 10.603 h 6.152 l 12.41,-12.41 c 1.335,-1.335 2.773,-1.048 3.838,0.016 1.064,1.065 1.353,2.505 0.018,3.84 L -11.09,-14.947 0.018,-3.839 C 1.353,-2.505 1.064,-1.064 0,0"
    style="fill:#27a8d8;fill-opacity:1;fill-rule:nonzero;stroke:none"
    id="path412"
    inkscape:connector-curvature="0" />
</g>
<g
  style="fill:#27a8d8;fill-opacity:1"
  id="g414"
  transform="matrix(1.4370305,0,0,-1.4370305,416.04511,60.892334)">
 <path
    d="m 0,0 h 14.253 c 4.103,0 5.819,-0.941 5.819,-6.77 v -7.751 c 0,-5.754 -1.716,-6.684 -5.819,-6.684 L 0,-21.205 Z M 14.643,5.279 H -2.724 -2.726 c -1.505,0 -2.727,-0.815 -2.727,-2.702 v -26.308 c 0,-1.878 1.208,-2.7 2.704,-2.707 h 17.392 c 7.566,0 10.794,3.551 10.794,11.874 v 7.794 c 0,8.446 -3.228,12.049 -10.794,12.049"
    style="fill:#27a8d8;fill-opacity:1;fill-rule:nonzero;stroke:none"
    id="path416"
    inkscape:connector-curvature="0" />
</g>
<g
  style="fill:#27a8d8;fill-opacity:1"
  id="g418"
  transform="matrix(1.4370305,0,0,-1.4370305,392.20506,70.003681)">
 <path
    d="M 0,0 C 0,-4.642 -1.474,-5.774 -7.507,-5.774 H -18.909 V 6.339 H -7.507 C -1.474,6.339 0,5.18 0,0.433 Z m -7.248,11.62 h -14.389 c -1.506,0 -2.724,-0.815 -2.724,-2.702 v -26.314 c 0,-1.888 1.218,-2.702 2.724,-2.702 1.506,0 2.728,0.814 2.728,2.702 v 6.384 h 11.421 l 8.191,-8.191 c 1.334,-1.335 2.773,-1.048 3.836,0.016 1.065,1.065 1.354,2.505 0.02,3.84 l -5.149,5.148 c 0.165,0.055 0.595,0.201 0.735,0.257 3.647,1.473 5.307,4.607 5.307,9.9 v 0.519 c 0,7.914 -3.68,11.143 -12.7,11.143"
    style="fill:#27a8d8;fill-opacity:1;fill-rule:nonzero;stroke:none"
    id="path420"
    inkscape:connector-curvature="0" />
</g>
<g
  id="g422"
  transform="matrix(1.4370305,0,0,-1.4370305,346.93571,53.95708)"
  style="fill:#00222b">
 <path
    d="M 0,0 C -1.304,0.752 -2.769,0.658 -3.712,-0.976 L -18.78,-27.109 c -0.945,-1.634 -0.293,-2.95 1.01,-3.703 1.305,-0.753 2.767,-0.657 3.71,0.977 L 1.009,-3.702 C 1.952,-2.067 1.305,-0.752 0,0"
    style="fill:#00222b;fill-opacity:1;fill-rule:nonzero;stroke:none"
    id="path424"
    inkscape:connector-curvature="0" />
</g>
<g
  style="fill:#27a8d8;fill-opacity:1"
  id="g426"
  transform="matrix(1.4370305,0,0,-1.4370305,307.21675,53.305817)">
 <path
    d="m 0,0 c -1.506,0 -2.725,-0.815 -2.725,-2.702 v -10.323 h -18.303 v 10.323 c 0,1.887 -1.223,2.702 -2.729,2.702 -1.506,0 -2.724,-0.815 -2.724,-2.702 v -26.314 c 0,-1.887 1.218,-2.702 2.724,-2.702 1.506,0 2.729,0.815 2.729,2.702 v 10.884 h 18.303 v -10.884 c 0,-1.887 1.219,-2.702 2.725,-2.702 1.506,0 2.728,0.815 2.728,2.702 V -2.702 C 2.728,-0.815 1.506,0 0,0"
    style="fill:#27a8d8;fill-opacity:1;fill-rule:nonzero;stroke:none"
    id="path428"
    inkscape:connector-curvature="0" />
</g>
<g
  style="fill:#27a8d8;fill-opacity:1"
  id="g430"
  transform="matrix(1.4370305,0,0,-1.4370305,257.22938,53.305817)">
 <path
    d="m 0,0 h -22.799 c -1.83,0 -2.619,-1.182 -2.619,-2.642 0,-1.46 0.789,-2.644 2.619,-2.644 h 8.674 v -23.731 c 0,-1.886 1.219,-2.701 2.725,-2.701 1.505,0 2.727,0.815 2.727,2.701 V -5.286 H 0 c 1.83,0 2.619,1.184 2.619,2.644 C 2.619,-1.182 1.83,0 0,0"
    style="fill:#27a8d8;fill-opacity:1;fill-rule:nonzero;stroke:none"
    id="path432"
    inkscape:connector-curvature="0" />
</g>
<g
  id="g434"
  transform="matrix(1.4370305,0,0,-1.4370305,671.81554,60.283322)">
 <path
    d="M 0,0 C 0,2.703 2.177,4.855 4.855,4.855 7.559,4.855 9.712,2.703 9.712,0 9.712,-2.679 7.559,-4.855 4.855,-4.855 2.177,-4.855 0,-2.679 0,0 m 0.802,0 c 0,-2.222 1.809,-4.053 4.053,-4.053 2.246,0 4.055,1.831 4.055,4.053 0,2.268 -1.809,4.054 -4.055,4.054 C 2.611,4.054 0.802,2.268 0.802,0 M 4.719,-0.595 H 3.825 V -2.794 H 2.978 v 5.565 h 2.267 c 1.076,0 1.878,-0.664 1.878,-1.694 0,-1.237 -1.1,-1.65 -1.42,-1.65 L 7.192,-2.794 H 6.161 Z M 3.825,2.062 V 0.138 h 1.42 c 0.618,0 1.008,0.366 1.008,0.939 0,0.618 -0.39,0.985 -1.008,0.985 z"
    style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
    id="path436"
    inkscape:connector-curvature="0" />
</g>
<g
  id="g3838">
 <g
    style="fill:#00222b"
    transform="matrix(1.4370305,0,0,-1.4370305,157.10456,137.95382)"
    id="g438">
   <path
      id="path440"
      style="fill:#00222b;fill-opacity:1;fill-rule:nonzero;stroke:none"
      d="m 0,0 c -17.042,0 -30.907,13.865 -30.907,30.907 0,17.042 13.865,30.907 30.907,30.907 17.043,0 30.908,-13.864 30.908,-30.907 C 30.908,13.865 17.043,0 0,0 M 24.027,54.935 C 17.609,61.353 9.076,64.887 0,64.887 c -18.736,0 -33.979,-15.243 -33.979,-33.98 0,-18.736 15.243,-33.979 33.979,-33.979 18.736,0 33.98,15.243 33.98,33.979 0,9.077 -3.535,17.61 -9.953,24.028" />
 </g>
 <g
    style="fill:#00222b"
    transform="matrix(1.4370305,0,0,-1.4370305,181.00639,77.777446)"
    id="g442">
   <path
      id="path444"
      style="fill:#00222b;fill-opacity:1;fill-rule:nonzero;stroke:none"
      d="m 0,0 -11.764,-10.591 c -0.123,-0.111 -0.193,-0.269 -0.193,-0.435 v -4.532 c 0,-0.166 -0.07,-0.323 -0.193,-0.435 l -1.438,-1.295 c -0.048,-0.042 -0.122,-0.008 -0.122,0.055 V 5.98 c 0,2.041 -1.309,2.923 -2.923,2.923 -1.613,0 -2.922,-0.882 -2.922,-2.923 v -23.213 c 0,-0.063 -0.075,-0.097 -0.122,-0.055 l -1.438,1.295 c -0.123,0.112 -0.194,0.269 -0.194,0.435 v 4.532 c 0,0.166 -0.07,0.324 -0.193,0.435 L -33.265,0 c -1.516,1.366 -3.047,0.983 -4.127,-0.216 -1.08,-1.2 -1.3,-2.762 0.217,-4.127 l 10.784,-9.711 c 0.129,-0.116 0.129,-0.318 0,-0.434 l -8.154,-7.343 c -1.518,-1.365 -1.297,-2.927 -0.217,-4.127 1.08,-1.199 2.611,-1.582 4.127,-0.216 l 8.755,7.883 c 0.056,0.05 0.14,0.05 0.195,0 l 2.034,-1.831 c 0.061,-0.055 0.096,-0.134 0.096,-0.216 v -7.579 c 0,-2.041 1.309,-2.922 2.922,-2.922 1.614,0 2.923,0.881 2.923,2.922 v 7.579 c 0,0.082 0.035,0.161 0.097,0.216 l 2.032,1.83 c 0.056,0.051 0.141,0.051 0.196,0 l 8.754,-7.882 c 1.518,-1.366 3.048,-0.983 4.127,0.216 1.08,1.2 1.301,2.762 -0.217,4.127 l -8.154,7.343 c -0.129,0.116 -0.129,0.318 0,0.434 L 3.91,-4.343 c 1.517,1.365 1.296,2.927 0.217,4.127 C 3.046,0.983 1.517,1.366 0,0" />
 </g>
</g>
<g
  aria-label="BLANKOS PERF STATS"
  transform="matrix(0.34967915,0,0,0.35392339,28.255348,-21.963286)"
  style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:113.33333588px;line-height:1.25;font-family:'Arial Rounded MT Bold';-inkscape-font-specification:'Arial Rounded MT Bold, ';letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none"
  id="flowRoot3775">
 <path
    d="m 588.97852,438.75236 h -24.79167 q -5.36784,0 -7.69206,-2.37955 -2.26888,-2.4349 -2.26888,-7.69206 v -60.98307 q 0,-5.36784 2.32422,-7.69206 2.37956,-2.37956 7.63672,-2.37956 h 26.28581 q 5.81054,0 10.07161,0.7194 4.26107,0.7194 7.63672,2.76693 2.87761,1.71549 5.09115,4.37174 2.21354,2.60092 3.37565,5.81055 1.16211,3.1543 1.16211,6.69596 0,12.17448 -12.17448,17.81902 15.99284,5.09114 15.99284,19.81119 0,6.80664 -3.48633,12.28516 -3.48633,5.42318 -9.40755,8.02409 -3.70769,1.54948 -8.52214,2.21354 -4.81445,0.60872 -11.23372,0.60872 z m -1.21745,-36.02539 h -17.09961 v 23.6849 h 17.65299 q 16.65691,0 16.65691,-12.00846 0,-6.14258 -4.31641,-8.90951 -4.31641,-2.76693 -12.89388,-2.76693 z m -17.09961,-32.76041 v 20.9733 h 15.05208 q 6.14258,0 9.46289,-1.16211 3.37565,-1.16211 5.14649,-4.42708 1.38346,-2.32422 1.38346,-5.20182 0,-6.14258 -4.37174,-8.13477 -4.37175,-2.04752 -13.33659,-2.04752 z"
    style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:113.33333588px;font-family:'Arial Rounded MT Bold';-inkscape-font-specification:'Arial Rounded MT Bold, '"
    id="path3793"
    inkscape:connector-curvature="0" />
 <path
    d="m 651.89844,366.20353 v 59.04623 h 33.3138 q 3.98438,0 6.08724,1.93685 2.15821,1.93685 2.15821,4.86979 0,2.98828 -2.10287,4.86979 -2.10286,1.82617 -6.14258,1.82617 h -39.67773 q -5.36784,0 -7.7474,-2.37955 -2.32422,-2.37956 -2.32422,-7.69206 v -62.47722 q 0,-4.98046 2.21355,-7.4707 2.26888,-2.49023 5.92122,-2.49023 3.70768,0 5.97656,2.49023 2.32422,2.4349 2.32422,7.4707 z"
    style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:113.33333588px;font-family:'Arial Rounded MT Bold';-inkscape-font-specification:'Arial Rounded MT Bold, '"
    id="path3795"
    inkscape:connector-curvature="0" />
 <path
    d="m 754.68977,429.56617 -3.87369,-10.1823 h -32.98177 l -3.8737,10.40365 q -2.26888,6.08724 -3.8737,8.24544 -1.60482,2.10287 -5.25716,2.10287 -3.09896,0 -5.47852,-2.26888 -2.37956,-2.26888 -2.37956,-5.14649 0,-1.66015 0.55339,-3.43099 0.55339,-1.77083 1.82617,-4.92513 l 20.75196,-52.68229 q 0.88541,-2.26888 2.10286,-5.42318 1.27279,-3.20963 2.65625,-5.3125 1.4388,-2.10286 3.70768,-3.37565 2.32422,-1.32812 5.69987,-1.32812 3.43099,0 5.69987,1.32812 2.32422,1.27279 3.70768,3.32031 1.43881,2.04753 2.37956,4.42709 0.9961,2.32422 2.49024,6.25325 l 21.19466,52.35026 q 2.49023,5.97657 2.49023,8.68816 0,2.82226 -2.37955,5.20182 -2.32422,2.32422 -5.64454,2.32422 -1.93684,0 -3.32031,-0.7194 -1.38346,-0.66407 -2.32422,-1.82617 -0.94075,-1.21745 -2.04752,-3.65235 -1.05144,-2.49023 -1.82618,-4.37174 z m -32.53906,-22.52279 h 24.23828 l -12.22981,-33.47982 z"
    style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:113.33333588px;font-family:'Arial Rounded MT Bold';-inkscape-font-specification:'Arial Rounded MT Bold, '"
    id="path3797"
    inkscape:connector-curvature="0" />
 <path
    d="m 805.73959,366.09286 31.59831,47.8125 v -48.25521 q 0,-4.70378 1.99218,-7.028 2.04753,-2.37955 5.47852,-2.37955 3.54167,0 5.58919,2.37955 2.04753,2.32422 2.04753,7.028 v 63.80534 q 0,10.68034 -8.85417,10.68034 -2.21354,0 -3.98437,-0.66406 -1.77084,-0.60873 -3.32032,-1.99219 -1.54947,-1.38347 -2.8776,-3.20964 -1.32812,-1.88151 -2.65625,-3.81836 l -30.82357,-47.25911 v 47.53581 q 0,4.64843 -2.1582,7.02799 -2.1582,2.37956 -5.53386,2.37956 -3.48632,0 -5.58919,-2.37956 -2.10286,-2.4349 -2.10286,-7.02799 v -62.5879 q 0,-3.98437 0.88541,-6.25325 1.05144,-2.49024 3.48633,-4.03972 2.4349,-1.60481 5.25716,-1.60481 2.21355,0 3.76302,0.7194 1.60482,0.7194 2.76693,1.93685 1.21745,1.21745 2.4349,3.15429 1.27278,1.93685 2.60091,4.03972 z"
    style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:113.33333588px;font-family:'Arial Rounded MT Bold';-inkscape-font-specification:'Arial Rounded MT Bold, '"
    id="path3799"
    inkscape:connector-curvature="0" />
 <path
    d="m 886.97656,366.20353 v 28.44402 l 32.26237,-33.64584 q 2.32422,-2.43489 3.92904,-3.597 1.66016,-1.16211 4.37175,-1.16211 3.54166,0 5.69986,2.10286 2.21355,2.04753 2.21355,5.03581 0,3.597 -3.70769,7.13867 l -19.97721,18.92578 23.02083,32.98177 q 2.4349,3.43099 3.65235,6.03191 1.21745,2.54557 1.21745,4.98046 0,2.71159 -2.15821,4.70378 -2.10286,1.99219 -5.75521,1.99219 -3.43099,0 -5.58919,-1.4388 -2.1582,-1.49414 -3.65234,-3.92904 -1.49414,-2.4349 -2.65625,-4.42708 l -19.42383,-29.88282 -13.44727,12.89388 v 16.76758 q 0,5.09115 -2.32422,7.58138 -2.32421,2.4349 -5.97656,2.4349 -2.10286,0 -4.03971,-1.10677 -1.93685,-1.10677 -2.98828,-2.98828 -0.77474,-1.54948 -0.94076,-3.59701 -0.16601,-2.04753 -0.16601,-5.92122 v -60.31902 q 0,-4.98046 2.1582,-7.4707 2.21354,-2.49023 5.97656,-2.49023 3.70768,0 5.97656,2.49023 2.32422,2.4349 2.32422,7.4707 z"
    style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:113.33333588px;font-family:'Arial Rounded MT Bold';-inkscape-font-specification:'Arial Rounded MT Bold, '"
    id="path3801"
    inkscape:connector-curvature="0" />
 <path
    d="m 985.64518,356.2426 q 12.61719,0 21.63742,5.14648 9.0755,5.09115 13.7239,14.55404 4.6484,9.40755 4.6484,22.13542 0,9.40755 -2.5455,17.09961 -2.5456,7.69205 -7.6367,13.33658 -5.0912,5.64454 -12.5066,8.63282 -7.41532,2.98828 -16.98889,2.98828 -9.51822,0 -17.04427,-3.04362 -7.52604,-3.09896 -12.56185,-8.68815 -5.0358,-5.5892 -7.63671,-13.44727 -2.54558,-7.85807 -2.54558,-16.98893 0,-9.35222 2.65625,-17.15495 2.71159,-7.80273 7.80274,-13.28125 5.09114,-5.47852 12.39583,-8.35612 7.30469,-2.93294 16.60156,-2.93294 z m 23.35282,41.72526 q 0,-8.90951 -2.8776,-15.43946 -2.8776,-6.52994 -8.2454,-9.85026 -5.3125,-3.37565 -12.22982,-3.37565 -4.92513,0 -9.13086,1.88151 -4.15039,1.82618 -7.19401,5.36784 -2.98828,3.54167 -4.75911,9.07552 -1.7155,5.47852 -1.7155,12.3405 0,6.91732 1.7155,12.50651 1.77083,5.58919 4.92513,9.29687 3.20963,3.65235 7.30469,5.47852 4.15039,1.82617 9.07552,1.82617 6.30859,0 11.56575,-3.1543 5.31251,-3.15429 8.41151,-9.73958 3.1542,-6.58529 3.1542,-16.21419 z"
    style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:113.33333588px;font-family:'Arial Rounded MT Bold';-inkscape-font-specification:'Arial Rounded MT Bold, '"
    id="path3803"
    inkscape:connector-curvature="0" />
 <path
    d="m 1101.4134,414.40341 q 0,7.36002 -3.8183,13.22591 -3.7631,5.86588 -11.0678,9.1862 -7.3046,3.32031 -17.3209,3.32031 -12.0085,0 -19.8112,-4.53776 -5.5339,-3.26498 -9.0202,-8.68815 -3.431,-5.47852 -3.431,-10.625 0,-2.98829 2.0475,-5.09115 2.1029,-2.1582 5.3125,-2.1582 2.6009,0 4.3718,1.66015 1.8262,1.66016 3.0989,4.92513 1.5495,3.8737 3.3204,6.47461 1.8261,2.60091 5.0911,4.31641 3.265,1.66016 8.5775,1.66016 7.3047,0 11.8424,-3.37566 4.5931,-3.43099 4.5931,-8.52213 0,-4.03971 -2.4902,-6.52995 -2.4349,-2.54557 -6.3639,-3.8737 -3.8737,-1.32812 -10.4037,-2.82226 -8.7435,-2.04753 -14.6647,-4.75912 -5.8659,-2.76692 -9.3522,-7.4707 -3.431,-4.75912 -3.431,-11.78711 0,-6.69596 3.6523,-11.89779 3.6524,-5.20182 10.5697,-7.96875 6.9173,-2.82226 16.2695,-2.82226 7.4707,0 12.8939,1.88151 5.4785,1.82617 9.0755,4.92513 3.597,3.04362 5.2572,6.41927 1.6601,3.37565 1.6601,6.58529 0,2.93294 -2.1028,5.3125 -2.0475,2.32421 -5.1465,2.32421 -2.8223,0 -4.3164,-1.38346 -1.4388,-1.4388 -3.1543,-4.64844 -2.2135,-4.5931 -5.3125,-7.13867 -3.099,-2.60091 -9.9609,-2.60091 -6.364,0 -10.293,2.82227 -3.8737,2.76692 -3.8737,6.69596 0,2.43489 1.3281,4.20573 1.3281,1.77083 3.6524,3.04362 2.3242,1.27278 4.7037,1.99219 2.3796,0.7194 7.8581,2.10286 6.862,1.60482 12.3958,3.54167 5.5892,1.93685 9.4629,4.70377 3.9291,2.76693 6.0873,7.028 2.2135,4.20573 2.2135,10.34831 z"
    style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:113.33333588px;font-family:'Arial Rounded MT Bold';-inkscape-font-specification:'Arial Rounded MT Bold, '"
    id="path3805"
    inkscape:connector-curvature="0" />
 <path
    d="m 1175.2903,407.15406 h -15.052 v 22.96549 q 0,4.92513 -2.3243,7.47071 -2.3242,2.54557 -5.8658,2.54557 -3.7077,0 -5.9766,-2.49024 -2.2689,-2.54557 -2.2689,-7.41536 v -62.53255 q 0,-5.42318 2.4902,-7.7474 2.4903,-2.32422 7.9135,-2.32422 h 21.0839 q 9.3523,0 14.3881,1.4388 4.9804,1.38347 8.5774,4.5931 3.6524,3.20964 5.5339,7.85807 1.8815,4.64844 1.8815,10.45899 0,12.39583 -7.6367,18.8151 -7.6367,6.36394 -22.7442,6.36394 z m -3.9843,-37.1875 h -11.0677 v 24.79166 h 11.0677 q 5.8105,0 9.6842,-1.21745 3.9291,-1.21744 5.9766,-3.98437 2.0475,-2.76693 2.0475,-7.24935 0,-5.36784 -3.1543,-8.74349 -3.5417,-3.597 -14.554,-3.597 z"
    style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:113.33333588px;font-family:'Arial Rounded MT Bold';-inkscape-font-specification:'Arial Rounded MT Bold, '"
    id="path3807"
    inkscape:connector-curvature="0" />
 <path
    d="m 1272.9629,370.29859 h -37.0215 v 19.92187 h 34.0885 q 3.763,0 5.5892,1.7155 1.8815,1.66015 1.8815,4.42708 0,2.76693 -1.8261,4.53776 -1.8262,1.7155 -5.6446,1.7155 h -34.0885 v 23.07617 h 38.2943 q 3.8737,0 5.8105,1.82617 1.9922,1.77083 1.9922,4.75911 0,2.87761 -1.9922,4.70378 -1.9368,1.77083 -5.8105,1.77083 h -44.6582 q -5.3679,0 -7.7474,-2.37955 -2.3243,-2.37956 -2.3243,-7.69206 v -60.98307 q 0,-3.54167 1.0515,-5.75521 1.0514,-2.26888 3.2649,-3.26498 2.2689,-1.05143 5.7553,-1.05143 h 43.3854 q 3.929,0 5.8105,1.77083 1.9369,1.7155 1.9369,4.53776 0,2.87761 -1.9369,4.64844 -1.8815,1.7155 -5.8105,1.7155 z"
    style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:113.33333588px;font-family:'Arial Rounded MT Bold';-inkscape-font-specification:'Arial Rounded MT Bold, '"
    id="path3809"
    inkscape:connector-curvature="0" />
 <path
    d="m 1317.289,404.0551 h -5.7552 v 26.06445 q 0,5.14649 -2.2688,7.58138 -2.2689,2.4349 -5.9213,2.4349 -3.929,0 -6.0872,-2.54557 -2.1582,-2.54558 -2.1582,-7.47071 v -62.42187 q 0,-5.3125 2.3795,-7.69206 2.3796,-2.37956 7.6921,-2.37956 h 26.7285 q 5.5339,0 9.4629,0.49805 3.929,0.44271 7.0833,1.88151 3.8184,1.60482 6.7513,4.5931 2.933,2.98828 4.4271,6.97265 1.5495,3.92904 1.5495,8.35612 0,9.07552 -5.1465,14.4987 -5.0911,5.42318 -15.4948,7.69206 4.3718,2.32422 8.3562,6.86198 3.9843,4.53776 7.0833,9.68424 3.1543,5.09115 4.8698,9.24154 1.7708,4.09505 1.7708,5.64453 0,1.60482 -1.0514,3.20964 -0.9961,1.54948 -2.7669,2.43489 -1.7709,0.94076 -4.0951,0.94076 -2.7669,0 -4.6484,-1.32813 -1.8816,-1.27278 -3.265,-3.26497 -1.3281,-1.99219 -3.6524,-5.86589 l -6.5852,-10.95703 q -3.5417,-6.0319 -6.364,-9.1862 -2.7669,-3.15429 -5.6445,-4.3164 -2.8776,-1.16211 -7.2494,-1.16211 z m 9.4076,-34.08854 h -15.1628 v 22.30143 h 14.7201 q 5.9212,0 9.9609,-0.9961 4.0397,-1.05143 6.1426,-3.48632 2.1582,-2.49024 2.1582,-6.80664 0,-3.37566 -1.7155,-5.92123 -1.7155,-2.60091 -4.7591,-3.8737 -2.8776,-1.21744 -11.3444,-1.21744 z"
    style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:113.33333588px;font-family:'Arial Rounded MT Bold';-inkscape-font-specification:'Arial Rounded MT Bold, '"
    id="path3811"
    inkscape:connector-curvature="0" />
 <path
    d="m 1425.1439,370.29859 h -32.373 v 20.75195 h 27.0605 q 3.763,0 5.5892,1.7155 1.8815,1.66015 1.8815,4.48242 0,2.82226 -1.8815,4.48242 -1.8815,1.66016 -5.5892,1.66016 h -27.0605 v 26.72851 q 0,5.09115 -2.3243,7.58138 -2.2688,2.4349 -5.8659,2.4349 -3.6523,0 -5.9765,-2.49024 -2.2689,-2.49023 -2.2689,-7.52604 v -62.42187 q 0,-3.54167 1.0514,-5.75521 1.0515,-2.26888 3.265,-3.26498 2.2689,-1.05143 5.7552,-1.05143 h 38.737 q 3.929,0 5.8105,1.77083 1.9369,1.7155 1.9369,4.53776 0,2.87761 -1.9369,4.64844 -1.8815,1.7155 -5.8105,1.7155 z"
    style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:113.33333588px;font-family:'Arial Rounded MT Bold';-inkscape-font-specification:'Arial Rounded MT Bold, '"
    id="path3813"
    inkscape:connector-curvature="0" />
 <path
    d="m 1535.0462,414.40341 q 0,7.36002 -3.8183,13.22591 -3.7631,5.86588 -11.0677,9.1862 -7.3047,3.32031 -17.321,3.32031 -12.0085,0 -19.8112,-4.53776 -5.5339,-3.26498 -9.0202,-8.68815 -3.431,-5.47852 -3.431,-10.625 0,-2.98829 2.0475,-5.09115 2.1029,-2.1582 5.3125,-2.1582 2.601,0 4.3718,1.66015 1.8262,1.66016 3.099,4.92513 1.5494,3.8737 3.3203,6.47461 1.8261,2.60091 5.0911,4.31641 3.265,1.66016 8.5775,1.66016 7.3047,0 11.8424,-3.37566 4.5931,-3.43099 4.5931,-8.52213 0,-4.03971 -2.4902,-6.52995 -2.4349,-2.54557 -6.3639,-3.8737 -3.8737,-1.32812 -10.4037,-2.82226 -8.7435,-2.04753 -14.6647,-4.75912 -5.8659,-2.76692 -9.3522,-7.4707 -3.431,-4.75912 -3.431,-11.78711 0,-6.69596 3.6523,-11.89779 3.6524,-5.20182 10.5697,-7.96875 6.9173,-2.82226 16.2695,-2.82226 7.4707,0 12.8939,1.88151 5.4785,1.82617 9.0755,4.92513 3.597,3.04362 5.2572,6.41927 1.6602,3.37565 1.6602,6.58529 0,2.93294 -2.1029,5.3125 -2.0475,2.32421 -5.1465,2.32421 -2.8223,0 -4.3164,-1.38346 -1.4388,-1.4388 -3.1543,-4.64844 -2.2135,-4.5931 -5.3125,-7.13867 -3.099,-2.60091 -9.9609,-2.60091 -6.364,0 -10.293,2.82227 -3.8737,2.76692 -3.8737,6.69596 0,2.43489 1.3281,4.20573 1.3282,1.77083 3.6524,3.04362 2.3242,1.27278 4.7037,1.99219 2.3796,0.7194 7.8581,2.10286 6.862,1.60482 12.3959,3.54167 5.5891,1.93685 9.4628,4.70377 3.9291,2.76693 6.0873,7.028 2.2135,4.20573 2.2135,10.34831 z"
    style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:113.33333588px;font-family:'Arial Rounded MT Bold';-inkscape-font-specification:'Arial Rounded MT Bold, '"
    id="path3815"
    inkscape:connector-curvature="0" />
 <path
    d="m 1601.7845,371.184 h -17.9297 v 58.93555 q 0,5.09115 -2.2689,7.58138 -2.2688,2.4349 -5.8658,2.4349 -3.6524,0 -5.9766,-2.49024 -2.2689,-2.49023 -2.2689,-7.52604 V 371.184 h -17.9297 q -4.2057,0 -6.2532,-1.82617 -2.0476,-1.88151 -2.0476,-4.92513 0,-3.1543 2.1029,-4.98047 2.1582,-1.82617 6.1979,-1.82617 h 52.2396 q 4.2611,0 6.3086,1.88151 2.1029,1.88151 2.1029,4.92513 0,3.04362 -2.1029,4.92513 -2.1029,1.82617 -6.3086,1.82617 z"
    style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:113.33333588px;font-family:'Arial Rounded MT Bold';-inkscape-font-specification:'Arial Rounded MT Bold, '"
    id="path3817"
    inkscape:connector-curvature="0" />
 <path
    d="m 1664.7321,429.56617 -3.8737,-10.1823 h -32.9818 l -3.8737,10.40365 q -2.2689,6.08724 -3.8737,8.24544 -1.6048,2.10287 -5.2572,2.10287 -3.0989,0 -5.4785,-2.26888 -2.3795,-2.26888 -2.3795,-5.14649 0,-1.66015 0.5533,-3.43099 0.5534,-1.77083 1.8262,-4.92513 l 20.752,-52.68229 q 0.8854,-2.26888 2.1028,-5.42318 1.2728,-3.20963 2.6563,-5.3125 1.4388,-2.10286 3.7077,-3.37565 2.3242,-1.32812 5.6998,-1.32812 3.431,0 5.6999,1.32812 2.3242,1.27279 3.7077,3.32031 1.4388,2.04753 2.3795,4.42709 0.9961,2.32422 2.4903,6.25325 l 21.1946,52.35026 q 2.4903,5.97657 2.4903,8.68816 0,2.82226 -2.3796,5.20182 -2.3242,2.32422 -5.6445,2.32422 -1.9369,0 -3.3203,-0.7194 -1.3835,-0.66407 -2.3242,-1.82617 -0.9408,-1.21745 -2.0476,-3.65235 -1.0514,-2.49023 -1.8261,-4.37174 z m -32.5391,-22.52279 h 24.2383 l -12.2298,-33.47982 z"
    style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:113.33333588px;font-family:'Arial Rounded MT Bold';-inkscape-font-specification:'Arial Rounded MT Bold, '"
    id="path3819"
    inkscape:connector-curvature="0" />
 <path
    d="m 1736.9212,371.184 h -17.9297 v 58.93555 q 0,5.09115 -2.2688,7.58138 -2.2689,2.4349 -5.8659,2.4349 -3.6524,0 -5.9766,-2.49024 -2.2689,-2.49023 -2.2689,-7.52604 V 371.184 h -17.9297 q -4.2057,0 -6.2532,-1.82617 -2.0475,-1.88151 -2.0475,-4.92513 0,-3.1543 2.1028,-4.98047 2.1582,-1.82617 6.1979,-1.82617 h 52.2396 q 4.2611,0 6.3086,1.88151 2.1029,1.88151 2.1029,4.92513 0,3.04362 -2.1029,4.92513 -2.1028,1.82617 -6.3086,1.82617 z"
    style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:113.33333588px;font-family:'Arial Rounded MT Bold';-inkscape-font-specification:'Arial Rounded MT Bold, '"
    id="path3821"
    inkscape:connector-curvature="0" />
 <path
    d="m 1816.8302,414.40341 q 0,7.36002 -3.8184,13.22591 -3.763,5.86588 -11.0677,9.1862 -7.3047,3.32031 -17.321,3.32031 -12.0084,0 -19.8112,-4.53776 -5.5338,-3.26498 -9.0202,-8.68815 -3.4309,-5.47852 -3.4309,-10.625 0,-2.98829 2.0475,-5.09115 2.1028,-2.1582 5.3125,-2.1582 2.6009,0 4.3717,1.66015 1.8262,1.66016 3.099,4.92513 1.5495,3.8737 3.3203,6.47461 1.8262,2.60091 5.0911,4.31641 3.265,1.66016 8.5775,1.66016 7.3047,0 11.8425,-3.37566 4.5931,-3.43099 4.5931,-8.52213 0,-4.03971 -2.4903,-6.52995 -2.4349,-2.54557 -6.3639,-3.8737 -3.8737,-1.32812 -10.4036,-2.82226 -8.7435,-2.04753 -14.6648,-4.75912 -5.8658,-2.76692 -9.3522,-7.4707 -3.431,-4.75912 -3.431,-11.78711 0,-6.69596 3.6524,-11.89779 3.6523,-5.20182 10.5696,-7.96875 6.9174,-2.82226 16.2696,-2.82226 7.4707,0 12.8939,1.88151 5.4785,1.82617 9.0755,4.92513 3.597,3.04362 5.2571,6.41927 1.6602,3.37565 1.6602,6.58529 0,2.93294 -2.1029,5.3125 -2.0475,2.32421 -5.1465,2.32421 -2.8222,0 -4.3164,-1.38346 -1.4388,-1.4388 -3.1543,-4.64844 -2.2135,-4.5931 -5.3125,-7.13867 -3.0989,-2.60091 -9.9609,-2.60091 -6.3639,0 -10.293,2.82227 -3.8737,2.76692 -3.8737,6.69596 0,2.43489 1.3282,4.20573 1.3281,1.77083 3.6523,3.04362 2.3242,1.27278 4.7038,1.99219 2.3795,0.7194 7.8581,2.10286 6.8619,1.60482 12.3958,3.54167 5.5892,1.93685 9.4629,4.70377 3.929,2.76693 6.0872,7.028 2.2136,4.20573 2.2136,10.34831 z"
    style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:113.33333588px;font-family:'Arial Rounded MT Bold';-inkscape-font-specification:'Arial Rounded MT Bold, '"
    id="path3823"
    inkscape:connector-curvature="0" />
</g>
`]